import React from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection2 = ({ lang }) => {
  return (
    <section className="home-section home-section--2">
      <div className="home-section__video">
        <ReactPlayer
          url="https://player.vimeo.com/external/477940538.hd.mp4?s=f4eca3df86010684d45ff1c09d7d3f0105119110&profile_id=175"
          width="100%"
          heigth="100%"
          loop={true}
          playing={true}
          controls={false}
          muted={true}
          playsinline={true}
        />
      </div>
      <div className="home-section__wrapper">
        <div className="home-section__wrapper-inner home-section__wrapper-inner-2">
          <h3>
            <div
              dangerouslySetInnerHTML={{
                __html: t("The <span>Healthy</span> Choice", lang),
              }}
            />
          </h3>
          <p>
            {t(
              "Salmon is a nutritious meal with a rich content of omega-3 fatty acids, vitamins and protein. Hiddenfjord salmon contains no antibiotics or hormones.",
              lang
            )}
          </p>
          <Link to={t("/quality-choice/", lang)}>{t("Read more", lang)}</Link>
          <Link
            to={t("/certifications/", lang)}
            target="_blank"
            rel="noreferrer"
          >
            <div className="home-section__wrapper-logos">
              <img
                src={require("../../assets/images/globalgap-logo-green.png")}
                alt=""
                className="img-fluid"
              />
              <img
                src={require("../../assets/images/BAP_Certificate.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeSection2
