import React from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection8 = ({ lang }) => {
  return (
    <section className="home-section home-section--8">
      <div className="home-section__video">
        <ReactPlayer
          url={require("../../assets/images/home-section-8-back.mp4")}
          width="100%"
          heigth="100%"
          loop={true}
          playing={true}
          controls={false}
          muted={true}
          playsinline={true}
        />
      </div>
      <div className="home-section__wrapper">
        <div className="home-section__wrapper-inner home-section__wrapper-inner-8">
          <h3>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  "<span>Home</span> <br /> of the Atlantic Salmon",
                  lang
                ),
              }}
            />
          </h3>
          <p>
            {t(
              "For thousands of years the Atlantic salmon has migrated to the sea around the Faroe Islands to feed and grow and therefore has adapted to these pristine waters. This is the salmon’s natural home.",
              lang
            )}
          </p>
          <Link to={t("/home-of-the-atlantic-salmon/", lang)}>
            {t("Read more", lang)}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeSection8
