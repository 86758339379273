import React from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection9 = ({ lang }) => {
  return (
    <section className="home-section home-section--9">
      <div className="home-section__video">
        <ReactPlayer
          url="https://player.vimeo.com/external/477940542.hd.mp4?s=572410bdf8af338db220387199a46baa75840857&profile_id=175"
          width="100%"
          heigth="100%"
          loop={true}
          playing={true}
          controls={false}
          muted={true}
          playsinline={true}
        />
      </div>
      <div className="home-section__wrapper">
        <div className="home-section__wrapper-inner home-section__wrapper-inner-9">
          <h3>
            <div
              dangerouslySetInnerHTML={{
                __html: t("A <span>Family</span> Business", lang),
              }}
            />
          </h3>
          <p>
            {t(
              "Hiddenfjord is a family-owned company founded in 1929. We have an idealistic focus on environmental sustainability.",
              lang
            )}
          </p>
          <Link to={t("/our-company/", lang)}>{t("Read more", lang)}</Link>
          <p>
            {t(
              "The brothers, Atli and Regin Gregersen, manage the company today.",
              lang
            )}
          </p>
        </div>
      </div>
    </section>
  )
}

export default HomeSection9
