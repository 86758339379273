import React, { useState } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"
import CountUp from "react-countup"

import t from "locale"

const HomeSection1 = ({ lang }) => {
  const [modal, setModal] = useState(false)

  //calculate todays date
  let newDate = new Date()
  let date = newDate.getDate()
  let month = newDate.getMonth() + 1
  let year = newDate.getFullYear()

  //calculate difference
  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = new Date(2020, 10, 10)
  const secondDate = new Date(year, month, date)

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))

  return (
    <>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <section className="home-section home-section--1">
        <div className="home-section__video">
          <ReactPlayer
            url="https://player.vimeo.com/external/477940452.hd.mp4?s=ec1ec523cc6cba3cc157b35ca14b2db629628a25&profile_id=175"
            width="100%"
            heigth="100%"
            loop={true}
            playing={true}
            controls={false}
            muted={true}
            playsinline={true}
          />
        </div>
        <div className="home-section__wrapper wrapper wrapper--hero">
          <div className="home-section__wrapper-inner home-section__wrapper-inner-1">
            <h1>{t("Raised in the wild", lang)}</h1>
            <h2>{t("Salmon from the Faroe Islands", lang)}</h2>
            <span
              className="ico-play"
              onClick={e => {
                e.preventDefault()
                setModal(true)
              }}
              role="button"
              aria-label="Play video"
              tabIndex={0}
            ></span>
          </div>
          <div className="home-section__wrapper-listing">
            <Link to={t("/truly-sustainable-farming/", lang)}>
              {t("SUSTAINABILITY", lang)}
            </Link>
            <Link to={t("/quality-choice/", lang)}>{t("QUALITY", lang)}</Link>
            <Link to={t("/animal-welfare/", lang)}>
              {t("Animal welfare", lang)}
            </Link>
          </div>
          <div className="home-section__icons-container">
            <Link
              className="home-section__link home-section__link--1"
              to="/hiddenfjord-wins-2021-seal-business-sustainability-award-for-94-reduction-in-carbon-emissions"
            >
              <img
                src={require("../../assets/images/hero-ico-1.png")}
                className="home-section__icon img-fluid"
                alt=""
              />
            </Link>
            <Link to={t("/low-emission-shipment/", lang)}>
              <div className="home-section__wrapper-counter counter">
                <img
                  src={require(`../../assets/images/counter-icon${
                    lang === "de"
                      ? "-de"
                      : lang === "es"
                      ? "-es"
                      : lang === "fr"
                      ? "-fr"
                      : lang === "it"
                      ? "-it"
                      : ""
                  }.png`)}
                  alt="We stopped using air freight"
                  className="counter__icon"
                />
                <div className="counter__days days">
                  <p className="days__number">
                    <CountUp end={diffDays} duration={4} />
                  </p>
                  <p className="days__text">
                    {t("Days", lang)} <br /> {lang === "en" && t("ago", lang)}
                  </p>
                </div>
              </div>
            </Link>
            <Link
              className="home-section__link home-section__link--2"
              to="/hiddenfjord-wins-2021-seal-business-sustainability-award-for-94-reduction-in-carbon-emissions"
            >
              <img
                src={require("../../assets/images/hero-ico-2.png")}
                className="home-section__icon img-fluid"
                alt=""
              />
            </Link>
            <div className="home-section__icons-container-mobile">
              <Link to="/hiddenfjord-wins-2021-seal-business-sustainability-award-for-94-reduction-in-carbon-emissions">
                <img
                  src={require("../../assets/images/hero-ico-1.png")}
                  className="home-section__icon-mobile img-fluid"
                  alt=""
                />
              </Link>
              <Link to="/hiddenfjord-wins-2021-seal-business-sustainability-award-for-94-reduction-in-carbon-emissions">
                <img
                  src={require("../../assets/images/hero-ico-2.png")}
                  className="home-section__icon-mobile img-fluid"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {modal && (
        <>
          <div className="subpage-modal">
            <div className="subpage-modal__wrapper">
              <button
                className="subpage-modal__close"
                onClick={e => {
                  setModal(false)
                }}
              >
                {t("Close", lang)}
              </button>
              <div className="embed-container">
                <ReactPlayer
                  url={
                    lang === "es"
                      ? "https://vimeo.com/877052927"
                      : lang === "it"
                      ? "https://vimeo.com/877067806"
                      : lang === "de"
                      ? "https://vimeo.com/877064129"
                      : lang === "fr"
                      ? "https://vimeo.com/877060291"
                      : "https://vimeo.com/877055954"
                  }
                  width="100%"
                  heigth="100%"
                  playing={modal === true ? true : false}
                  controls={true}
                  muted={modal === true ? true : false}
                  playsinline={true}
                />
              </div>
            </div>
          </div>
          <div
            className="subpage-modal__overlay"
            onClick={e => {
              setModal(false)
            }}
          ></div>
        </>
      )}
    </>
  )
}

export default HomeSection1
