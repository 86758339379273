import React from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection5 = ({ lang }) => {
  return (
    <section className="home-section home-section--5">
      <div className="home-section__video">
        <ReactPlayer
          url="https://player.vimeo.com/external/477940662.hd.mp4?s=efef3340538ab1953748d60c228b2e49c67e4a93&profile_id=175"
          width="100%"
          heigth="100%"
          loop={true}
          playing={true}
          controls={false}
          muted={true}
          playsinline={true}
        />
      </div>
      <div className="home-section__wrapper">
        <div className="home-section__wrapper-inner home-section__wrapper-inner-5">
          <h3>
            <div
              dangerouslySetInnerHTML={{
                __html: t("Truly <span>Sustainable</span> Farming", lang),
              }}
            />
          </h3>
          <p>
            {t(
              "Hiddenfjord salmon is raised in the world’s most exposed farming locations to secure optimal farming conditions. Sustainability is our core value and this is reflected in all our actions.",
              lang
            )}
          </p>
          <Link to={t("/truly-sustainable-farming/", lang)}>
            {t("Read more", lang)}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeSection5
