import React, { useState } from "react"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection4 = ({ lang }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <section className="home-section home-section--4">
        <div className="home-section__video">
          <ReactPlayer
            url="https://player.vimeo.com/external/477941687.hd.mp4?s=4d77bd7eb2c25fd58d8bc306b88ff5698c2078f7&profile_id=175"
            width="100%"
            heigth="100%"
            loop={true}
            playing={true}
            controls={false}
            muted={true}
            playsinline={true}
          />
        </div>
        <div className="home-section__wrapper">
          <div className="home-section__wrapper-inner home-section__wrapper-inner-4">
            <h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("Exceptional <span>Taste</span>", lang),
                }}
              />
            </h3>
            <p>
              <i>
                {t(
                  "Hiddenfjord salmon has a fantastic quality. It has a clean oceanic flavour. It lingers cleanly on the palate and has a nice butteriness.",
                  lang
                )}
              </i>
              <br />
              <br />
              {t("Dave Pasternack, Chef, New York", lang)}
            </p>
            <p
              className="link"
              onClick={e => {
                e.preventDefault()
                setModal(true)
              }}
              role="presentation"
            >
              {t("See video", lang)}
            </p>
          </div>
        </div>
      </section>
      {modal && (
        <>
          <div className="subpage-modal">
            <div className="subpage-modal__wrapper">
              <button
                className="subpage-modal__close"
                onClick={e => {
                  setModal(false)
                }}
              >
                {t("Close", lang)}
              </button>
              <div className="embed-container">
                <ReactPlayer
                  url={
                    lang === "es"
                      ? "https://vimeo.com/877048076"
                      : lang === "it"
                      ? "https://vimeo.com/877039580"
                      : lang === "de"
                      ? "https://vimeo.com/877043123"
                      : lang === "fr"
                      ? "https://vimeo.com/877050505"
                      : "https://vimeo.com/877045726"
                  }
                  width="100%"
                  heigth="100%"
                  playing={modal === true ? true : false}
                  controls={true}
                  muted={modal === true ? true : false}
                  playsinline={true}
                />
              </div>
            </div>
          </div>
          <div
            className="subpage-modal__overlay"
            onClick={e => {
              setModal(false)
            }}
            role="presentation"
          ></div>
        </>
      )}
    </>
  )
}

export default HomeSection4
