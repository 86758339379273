import React from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection3 = ({ lang }) => {
  return (
    <section className="home-section home-section--3">
      <div className="home-section__video">
        <ReactPlayer
          url="https://player.vimeo.com/external/477940638.hd.mp4?s=feb2748062db9ef196ef9f85ca96d6754424f750&profile_id=175"
          width="100%"
          heigth="100%"
          loop={true}
          playing={true}
          controls={false}
          muted={true}
          playsinline={true}
        />
      </div>
      <div className="home-section__wrapper">
        <div className="home-section__wrapper-inner home-section__wrapper-inner-3">
          <h3>
            <div
              dangerouslySetInnerHTML={{
                __html: t("Raised in the <span>Wild</span>", lang),
              }}
            />
          </h3>
          <p>
            {t(
              "Our salmon is raised in the pristine waters around the Faroe Islands. Farming in pens in the open sea with strong currents and high waves keeps the salmon fit and prevents pollution of the seabed.",
              lang
            )}
          </p>
          <Link to={t("/raised-in-the-wild/", lang)}>
            {t("Read more", lang)}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeSection3
