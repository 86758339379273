import React from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection7 = ({ lang }) => {
  return (
    <section className="home-section home-section--7">
      <div className="home-section__video">
        <ReactPlayer
          url="https://player.vimeo.com/external/477940522.hd.mp4?s=851a5280bd06da48e7198f3d1448136873e419f0&profile_id=175"
          width="100%"
          heigth="100%"
          loop={true}
          playing={true}
          controls={false}
          muted={true}
          playsinline={true}
        />
      </div>
      <div className="home-section__wrapper">
        <div className="home-section__wrapper-inner home-section__wrapper-inner-7">
          <img
            src={require("../../assets/images/ico-plane.png")}
            alt=""
            className="home-section__wrapper-icon"
          />
          <h3>
            <div
              dangerouslySetInnerHTML={{
                __html: t("<span>Low-emission</span> <br /> Shipment", lang),
              }}
            />
          </h3>
          <div
            className="home-section__wrapper-inner-desc"
            dangerouslySetInnerHTML={{
              __html: t(
                "10-10-2020 we stopped all our air freight. By replacing this major contributor to global CO<sub>2</sub>-emissions with sea freight, we have reduced our carbon footprint for overseas transport by 94%.",
                lang
              ),
            }}
          />
          <Link to={t("/low-emission-shipment/", lang)}>
            {t("Read more", lang)}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeSection7
