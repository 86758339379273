import React, { useState } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import t from "locale"

const HomeSection6 = ({ lang }) => {
  const [modal, setModal] = useState(false)

  return (
    <>
      <span className="anchor" id="stress-free-harvesting"></span>
      <section className="home-section home-section--6">
        <div className="home-section__video">
          <ReactPlayer
            url="https://player.vimeo.com/external/477941663.hd.mp4?s=c9e061c140812c83c73e5c6ee9b0a15eaaefc0fe&profile_id=175"
            width="100%"
            heigth="100%"
            loop={true}
            playing={true}
            controls={false}
            muted={true}
            playsinline={true}
          />
        </div>
        <div className="home-section__wrapper">
          <div className="home-section__wrapper-inner home-section__wrapper-inner-6">
            <h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("<span>Stress-free</span> Harvesting", lang),
                }}
              />
            </h3>
            <p>
              {t(
                "Our unique concept utilises the salmon's natural instinct to swim against the current. They are carefully guided from the open sea pens to the harvest basin and stunned without stress. This gives a better taste and longer shelf life.",
                lang
              )}
            </p>
            <p
              className="link"
              onClick={e => {
                e.preventDefault()
                setModal(true)
              }}
              role="presentation"
            >
              {t("See video", lang)}
            </p>
            <Link to={t("/animal-welfare/", lang)}>{t("Read more", lang)}</Link>
          </div>
        </div>
      </section>
      {modal && (
        <>
          <div className="subpage-modal">
            <div className="subpage-modal__wrapper">
              <button
                className="subpage-modal__close"
                onClick={() => setModal(false)}
              >
                {t("Close", lang)}
              </button>
              <div className="embed-container">
                <ReactPlayer
                  url={
                    lang === "es"
                      ? "https://vimeo.com/877036641"
                      : lang === "it"
                      ? "https://vimeo.com/877035930"
                      : lang === "de"
                      ? "https://vimeo.com/877036084"
                      : lang === "fr"
                      ? "https://vimeo.com/877036936"
                      : "https://vimeo.com/877036360"
                  }
                  width="100%"
                  heigth="100%"
                  playing={modal === true ? true : false}
                  controls={true}
                  muted={modal === true ? true : false}
                  playsinline={true}
                />
              </div>
            </div>
          </div>
          <div
            className="subpage-modal__overlay"
            onClick={() => setModal(false)}
            role="presentation"
          ></div>
        </>
      )}
    </>
  )
}

export default HomeSection6
